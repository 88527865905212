import { axiosInstance } from "config/https";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { formatDate } from "utils/common";
import "../plan.css";
import "./evolutions.css";

const Evolutions = () => {
  const [evolutionData, setEvolutionData] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage] = useState(12);
  const [showLoader, setShowLoader] = useState(false);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = evolutionData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(evolutionData.length / itemsPerPage);

  useEffect(() => {
    getEvolutions();
  }, []);

  const getEvolutions = () => {
    setShowLoader(true);
    axiosInstance
      .get("/api/marketing/me/evolutions")
      .then((response) => {
        setEvolutionData(response.data.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const checkVal = (val) => {
    return val ? val : "-";
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % evolutionData.length;
    setItemOffset(newOffset);
  };
  return (
    <>
      <h3 className="mb-30 mt-0 pl-20 pr-20 account-header">Mes Evolutions</h3>
      {showLoader && (
        <div className="loader-wrapper mt-40">
          <div className="loader"></div>
        </div>
      )}
      <div className="row">
        {currentItems.length > 0 &&
          currentItems.map((item) => (
            <div className="column column-4 evolution-box" key={item._id}>
              <p className="text-12 mt-0 mb-20 date font-weight-600">
                {checkVal(formatDate(item.created_at))}
              </p>
              <p className="mb-20">
                <span className="weight font-weight-600">
                  {checkVal(item.weight)}
                </span>{" "}
                <span className="text-16">kg</span>
              </p>
              <div className="evolution-info text-center">
                <div className="item">
                  <p className="text-12 mb-5 mt-0">Poitrine</p>
                  <p className="m-0 font-weight-700">{checkVal(item.chest)}</p>
                </div>
                <div className="item">
                  <p className="text-12 mb-5 mt-0">Bras</p>
                  <p className="m-0 font-weight-700">{checkVal(item.arms)}</p>
                </div>
                <div className="item">
                  <p className="text-12 mb-5 mt-0">Taille</p>
                  <p className="m-0 font-weight-700">{checkVal(item.waist)}</p>
                </div>
                <div className="item">
                  <p className="text-12 mb-5 mt-0">Hanche</p>
                  <p className="m-0 font-weight-700">{checkVal(item.hips)}</p>
                </div>
                <div className="item">
                  <p className="text-12 mb-5 mt-0">Cuisses</p>
                  <p className="m-0 font-weight-700">{checkVal(item.legs)}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      {currentItems.length > 0 && (
        <div className="text-center mb-20">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Suivant"
            onPageChange={handlePageClick}
            pageRangeDisplayed={12}
            pageCount={pageCount}
            previousLabel="Précedent"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
      {!showLoader && currentItems.length === 0 && (
        <h2 className="text-center clr-gry mt-40">
          Rendez-vous sur l’application pour inscrire votre première évolution
        </h2>
      )}
    </>
  );
};

export default Evolutions;
