import { Link, useNavigate } from "react-router-dom";
import Cookie from "utils/cookie";
import "./menu.css";

const ResponsiveMenu = ({ isOpen, toggle }) => {
  const navigate = useNavigate();
  const token = Cookie.get("token");
  const activeLink = (link) => {
    const currentLink = window.location.pathname;
    if (currentLink === link) {
      return "active";
    } else {
      return "";
    }
  };
  return (
    <nav>
      <div className={`mobile-menu ${isOpen ? "translate-card" : ""}`}>
        <img
          src="/svg/close_white.svg"
          alt="close"
          onClick={toggle}
          className="close-menu"
        />
        <img src="/svg/logo.svg" alt="menu logo" />
        <ul id="mobile-menu" className="menu-items">
          <li className={activeLink("/")}>
            <a href="https://evodream.fr/">Accueil</a>
          </li>
          <li className={activeLink("/pricing")}>
            <Link to="/pricing">S’abonner</Link>
          </li>
          <li className={activeLink("/contact")}>
            <a href="https://evodream.fr/contact">Contact</a>
          </li>
        </ul>
        {!token && (
          <button
            class="d-inline-block customButton ctm-btn"
            onClick={() => navigate("/register")}
          >
            DÉMARRER
          </button>
        )}
        <div className="menu-bottom-links d-flex">
          <div className="b-link">
            <a
              className="text-dec-non text-12 clr-gry"
              href="https://evodream.fr/politique-de-confidentialite/"
              title="Confidentialité"
            >
              Confidentialité
            </a>
          </div>
          <div className="b-link">
            <a
              className="text-dec-non text-12 clr-gry"
              href="https://evodream.fr/cguv/"
              title="Conditions"
            >
              Conditions
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default ResponsiveMenu;
