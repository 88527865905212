import { axiosInstance } from "config/https";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import "./index.css";

const Confirmation = () => {
  const [searchParams] = useSearchParams();
  const [isChristmasPromo, setIsChristmasPromo] = useState(false);
  const getCheckoutIdParam = searchParams.get("checkoutid");
  const getPromonParam = searchParams.get("promo");
  const [dataLayer, setDataLayer] = useState({
    transactionTotal: 0,
    transactionCurrency: null,
    transactionID: null,
    transactionPromoCode: null,
    event: "awin.dl.ready",
  });

  useEffect(() => {
    if (getPromonParam === "noel") {
      setIsChristmasPromo(true);
    }
  }, [getPromonParam]);

  useEffect(() => {
    if (getCheckoutIdParam) {
      getDataLayerDetails(getCheckoutIdParam);
    } else {
      callTagManager(dataLayer);
    }
  }, []);
  useEffect(() => {
    callTagManager();
  }, [dataLayer]);

  const getDataLayerDetails = (getCheckoutIdParam) => {
    axiosInstance
      .get(`/checkout/sessions/${getCheckoutIdParam}`)
      .then((res) => {
        const response = res.data.data;
        setDataLayer({
          ...dataLayer,
          transactionTotal: response.transactionTotal,
          transactionCurrency: response.transactionCurrency,
          transactionID: response.transactionID,
          transactionPromoCode: response.transactionPromoCode,
        });
        const updatedDataLayer = {
          ...dataLayer,
          transactionTotal: response.transactionTotal,
          transactionCurrency: response.transactionCurrency,
          transactionID: response.transactionID,
          transactionPromoCode: response.transactionPromoCode,
        };
        callTagManager(updatedDataLayer);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const callTagManager = (dataLayer) => {
    const tagManagerArgs = {
      dataLayer,
    };
    TagManager.dataLayer(tagManagerArgs);
  };
  return (
    <div className="confirmation-box">
      <div className="confirmation-row">
        <span className="check-img">
          <img
            decoding="async"
            fetchpriority="high"
            src="/img/check-circle.png"
            alt=""
            title="CheckCircle"
            className="wp-image-35"
          />
        </span>
        <h1>
          {isChristmasPromo ? "Merci pour votre achat !" : "Félicitations"}
        </h1>
        {isChristmasPromo ? (
          <div className="cb-text-box">
            <p>
              Votre achat d'un montant total de{" "}
              <b>{dataLayer.transactionTotal}€</b> a été reçue et sera traitée
              sous peu. En attendant, lancez-vous dans votre parcours fitness en
              téléchargeant l'application Evodream.
            </p>
          </div>
        ) : (
          <div className="cb-text-box">
            <p>Bienvenue dans l’équipe Evodream !</p>
            <p>Vous pouvez télécharger l’application.</p>
          </div>
        )}
        <div className="ps-buttons mt-40">
          <a
            id="apple-download-cta"
            className="mobile-download-ctas"
            href="https://apps.apple.com/us/app/evodream/id1643197949"
          >
            <img
              decoding="async"
              src="/img/telecharger-depuis-1.png"
              alt=""
              title="Télécharger depuis (1)"
            />
          </a>
          <a
            id="android-download-cta"
            className="mobile-download-ctas"
            href="https://play.google.com/store/apps/details?id=com.evodream.app&amp;pcampaignid=web_share"
          >
            <img
              decoding="async"
              src="/img/telecharger-depuis-2.png"
              alt=""
              title="Télécharger depuis (2)"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
