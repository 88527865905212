import Button from "Component/Button";
import PasswordInput from "Component/PasswordInput";
import SocialMediaLogin from "Component/SocialMediaLogin";
import { axiosInstance } from "config/https";
import { Form, Formik } from "formik";
import { sha512 } from "js-sha512";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "utils/hooks/useDocumentTitle";

import Cookie from "utils/cookie";

const Login = () => {
  useDocumentTitle("Bon retour parmi nous - Connexion", true);
  const navigate = useNavigate();
  let FormData = {
    email: "",
    password: "",
  };

  const fromHandleSubmit = (data) => {
    let mainData = { ...data };
    mainData.password = sha512(data.password);
    const awcCookieVal = Cookie.get("awc");
    if (awcCookieVal) {
      mainData.awc = awcCookieVal;
    }
    return axiosInstance
      .post("/api/marketing/auth/signin", mainData)
      .then((res) => {
        Cookie.save("token", res.data.data["accessToken"]);
        toast.success("Logged In Successfully");
        navigate("/");
      })
      .catch((err) => {
        toast.error("Adresse email ou mot de passe incorrect");
      });
  };

  return (
    <div className="login-wrapper">
      <div className="row auth-box">
        <div className="column-6 column login-left-box">
          <div className="show-mobile mt-20 logo-box">
            <img
              src="/svg/evo_logo.svg"
              className="d-inline-block"
              alt="Evo Logo"
            />
            <h1 className="auth-title mt-10 mb-30">Bon retour parmi nous !</h1>
          </div>
          <div className="d-flex hide-mobile">
            <img src="/img/login_left_img.png" alt="Login image" />
          </div>
        </div>
        <div className="column-6 column login-right-box">
          <div className="text-center mt-20">
            <img
              src="/svg/evo_logo.svg"
              className="hide-mobile d-inline-block"
              alt="Evo Logo"
            />
            <h1 className="auth-title hide-mobile mt-20 mb-40">
              Bon retour parmi nous !
            </h1>
          </div>
          <SocialMediaLogin />
          <Formik
            initialValues={FormData}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Ajouter une adresse e-mail";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password) {
                errors.password = "Ajouter un mot de passe";
              } else if (values.password.length < 8) {
                errors.password = "Password must have 8 characters.";
              }

              return errors;
            }}
            onSubmit={fromHandleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="from-container">
                <div className="or-text text-14">ou</div>
                <div className="input-container">
                  <span className="input_label text-black text-upper">
                    Email{" "}
                  </span>
                  <input
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    id="email"
                    name="email"
                    placeholder="e.g. johndoe@gmail.com"
                    style={{
                      border: errors.email && touched.email && "2px solid red",
                    }}
                    className="custom-input"
                  />
                  {errors.email && touched.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="input-container mt-20 mb-10">
                  <span className="input_label text-black text-upper">
                    MOT DE PASSE{" "}
                  </span>
                  <PasswordInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    id="password"
                    name="password"
                    placeholder="Entrez votre mot de passe"
                    style={{
                      border:
                        errors.password && touched.password && "2px solid red",
                    }}
                    className="custom-input"
                  />
                  {errors.password && touched.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>
                <div className="mb-30 text-right">
                  <Link
                    className="input_label clr-gry text-dec-non"
                    to="/forgot-password"
                    relative="path"
                  >
                    Mot de passe oublié ?
                  </Link>
                </div>
                <Button
                  text="Continuer"
                  type="submit"
                  isButtonDisable={isSubmitting}
                  isLoading={isSubmitting}
                />
                <div className="text-black mt-40 text-center">
                  Je n’ai pas de compte.{" "}
                  <Link
                    className="clr-gry text-upper font-weight-600 text-dec-non"
                    to="/register"
                    relative="path"
                  >
                    Je m’inscris
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
