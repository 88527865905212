import CustomRouter from "Component/CustomBrowserRouter";
import history from "Component/CustomBrowserRouter/history";
import { Suspense } from "react";
import TagManager from "react-gtm-module";
import AppRoutes from "routes/AppRoutes";
import Toast from "./Component/Toast";

const App = () => {
  const tagManagerArgs = {
    gtmId: "GTM-TXZ9KJWD",
  };

  TagManager.initialize(tagManagerArgs);
  return (
    <Suspense fallback="En cours...">
      <CustomRouter history={history}>
        <AppRoutes />
        <Toast />
      </CustomRouter>
    </Suspense>
  );
};

export default App;
