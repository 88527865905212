import { axiosInstance } from "config/https";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import Cookie from "utils/cookie";

const SocialMediaLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [googleLoader, setGoogleLoader] = useState(false);
  const [facebookLoader, setFacebookLoader] = useState(false);
  const param = searchParams.get("plan");
  const awcCookieVal = Cookie.get("awc");
  const getPromoCodeParam = searchParams.get("code")
    ? searchParams.get("code")
    : Cookie.get("code");
  const googleLogin = (userData) => {
    const data = {
      token: userData.access_token,
      email: userData.email,
    };
    if (awcCookieVal) {
      data.awc = awcCookieVal;
    }
    const apiUrl = "/api/marketing/auth/social/google";
    if (data.token) {
      submitSocialMediaSignin(data, apiUrl);
    }
  };

  const facebookLogin = (userData) => {
    const data = {
      token: userData.accessToken,
      email: userData.email,
    };
    if (awcCookieVal) {
      data.awc = awcCookieVal;
    }
    const apiUrl = "/api/marketing/auth/social/facebook";
    if (data.token) {
      submitSocialMediaSignin(data, apiUrl);
    }
  };

  const submitSocialMediaSignin = (data, apiUrl) => {
    data = {
      ...data,
      referrer: "Marketing Website",
    };

    return axiosInstance
      .post(apiUrl, data)
      .then((res) => {
        const token = res.data.data["accessToken"];
        Cookie.save("token", token);
        toast.success("Logged In Successfully");
        const { user_id } = jwtDecode(token);
        const promoQueryParam = getPromoCodeParam
          ? `&prefilled_promo_code=${getPromoCodeParam}`
          : "";
        const userIdParam = user_id ? `&client_reference_id=${user_id}` : "";
        if (param === "1mois") {
          window.location.replace(
            `${process.env.REACT_APP_STRIPE_URL_1_MONTHS}?locale=fr&prefilled_email=${data.email}${promoQueryParam}${userIdParam}`
          );
        } else if (param === "6mois") {
          window.location.replace(
            `${process.env.REACT_APP_STRIPE_URL_6_MONTHS}?locale=fr&prefilled_email=${data.email}${promoQueryParam}${userIdParam}`
          );
        } else if (param === "12mois") {
          window.location.replace(
            `${process.env.REACT_APP_STRIPE_URL_12_MONTHS}?locale=fr&prefilled_email=${data.email}${promoQueryParam}${userIdParam}`
          );
        } else {
          navigate("/");
        }
        setFacebookLoader(false);
        setGoogleLoader(false);
      })
      .catch((err) => {
        setFacebookLoader(false);
        setGoogleLoader(false);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <div className="social-media-login mt-10">
      <LoginSocialGoogle
        client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || "client_id"}
        access_type="online"
        scope="email profile"
        onLoginStart={() => {
          setGoogleLoader(true);
        }}
        onResolve={({ data }) => {
          googleLogin(data);
        }}
        onReject={(err) => {
          setGoogleLoader(false);
          toast.error(err?.data?.message);
        }}
      >
        <button disabled={googleLoader} className="google-btn mb-20 ctm-btn">
          <img src="/svg/google.svg" alt="google" />
          <span className="text-16 mr-10">
            {googleLoader ? "En cours..." : "Continuer avec Google"}
          </span>
        </button>
      </LoginSocialGoogle>
      <LoginSocialFacebook
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        onLoginStart={() => {
          setFacebookLoader(true);
        }}
        onResolve={({ data }) => {
          facebookLogin(data);
        }}
        onReject={(err) => {
          setFacebookLoader(false);
          toast.error(err?.data?.message);
        }}
      >
        <button disabled={facebookLoader} className="facebook-btn ctm-btn">
          <img src="/svg/facebook.svg" alt="facebook" />
          <span className="text-16 mr-10">
            {facebookLoader ? "En cours..." : "Continuer avec Facebook"}
          </span>
          <span className="btn-loader"></span>
        </button>
      </LoginSocialFacebook>
    </div>
  );
};

export default SocialMediaLogin;
