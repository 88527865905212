import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
const EvoPlan = ({
  plan,
  activePlan,
  referrer = "In-App",
  portalLink = "",
  redirectUrl12Month = "/register?plan=12mois",
  redirectUrl1Month = "/register?plan=1mois",
}) => {
  const navigate = useNavigate();
  const [showTab, setShowTab] = useState(2);
  useEffect(() => {
    if (activePlan === "1") {
      setShowTab(1);
    } else {
      setShowTab(2);
    }
  }, [activePlan]);
  const planRedirection = () => {
    let url = "";
    if (plan === "pro") {
      if (showTab === 2) {
        url = redirectUrl12Month;
      } else {
        url = redirectUrl1Month;
      }
    } else {
      url = "/welcome";
    }
    if (url.split("https").length > 0) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };
  return (
    <div
      className={`price-box text-center ${plan === "pro" ? "pro" : ""} ${
        activePlan ? "has-active-plan" : ""
      }`}
    >
      <p className="title mt-0 font-poppins clr-gry">
        {plan === "pro" ? "EVO PRO" : "EVO BASIC"}
      </p>
      {plan === "pro" ? (
        <div className="price font-weight-700">
          {showTab === 2 ? "6,90" : "13,90"}€ / mois
        </div>
      ) : (
        <div className="price font-weight-700">0,00€</div>
      )}
      {plan === "pro" ? (
        <p className="clr-gry text-12 mb-20">
          {showTab === 2 ? "82,80" : "13,90"}€ prélevés tous les{" "}
          {showTab === 2 ? "ans" : "mois"}, sans engagement et résiliable à tout
          moment.
        </p>
      ) : (
        <p className="clr-gry text-12 mb-20">
          Téléchargez notre application dès aujourd'hui et commencez à explorer.
        </p>
      )}
      {plan !== "pro" && (
        <span className="evo-pro clr-gry text-12 mb-10">
          Optez pour Evo Pro si vous adorez !
        </span>
      )}
      {plan === "pro" ? (
        <div className="plan-selection">
          {showTab === 2 && (
            <span className="evo-pro clr-gry text-12 mb-10">
              Economisez €84.00 (-50%)
            </span>
          )}
        </div>
      ) : (
        <div className="plan-selection"></div>
      )}
      {plan === "pro" && (
        <div className="tabs text-12">
          <span
            id="monthly-toggle-pricing"
            data-id="monthly-toggle-pricing"
            onClick={() => setShowTab(1)}
            className={showTab === 1 ? "active" : ""}
          >
            mensuel
          </span>
          <span
            id="annual-toggle-pricing"
            data-id="annual-toggle-pricing"
            onClick={() => setShowTab(2)}
            className={showTab === 2 ? "active" : ""}
          >
            annuel
          </span>
        </div>
      )}
      <button
        title="essayer gratuitement"
        id={
          plan === "pro" ? "trial-primary-btn-pricing" : "demarrer-btn-pricing"
        }
        onClick={() => planRedirection()}
        data-id={
          plan === "pro" ? "trial-primary-btn-pricing" : "demarrer-btn-pricing"
        }
        className={`customButton mt-20 ctm-btn small pro-plan-button font-weight-600 ${
          plan !== "pro" ? "outline" : ""
        }`}
      >
        {plan === "pro" ? "essayer gratuitement" : "démarrer"}
      </button>
      <div className="active-plan-button">
        {referrer === "In-App" ? (
          <div className="mt-20 text-14 clr-gry font-weight-600">
            Veuillez visiter l'application pour gérer votre abonnement.
          </div>
        ) : (
          <button
            className="customButton mt-20 ctm-btn small font-weight-600"
            onClick={() => {
              if (portalLink) {
                window.location.href = portalLink;
              }
            }}
          >
            Modifier l'abonnement
          </button>
        )}
      </div>
      <hr className="mb-20 mt-20" />
      <p className="font-weight-700 text-left">
        Inclus dans votre abonnement :
      </p>
      <ul className="text-left text-14">
        <li>Programme “découverte”</li>
        <li>Kit recettes démarrage </li>
        <li className={plan !== "pro" ? "locked" : ""}>Suivi d’évolution</li>
        <li className={plan !== "pro" ? "locked" : ""}>
          Accompagnement E-Coach
        </li>
        <li className={plan !== "pro" ? "locked" : ""}>
          Programmes d’entrainements personnalisés par le E-coach
        </li>
        <li className={plan !== "pro" ? "locked" : ""}>
          Programmes de nutrition personnalisés par le E-coach
        </li>
        <li className={plan !== "pro" ? "locked" : ""}>+ de 50 programmes </li>
        <li className={plan !== "pro" ? "locked" : ""}>+ de 300 recettes </li>
      </ul>
    </div>
  );
};

export default EvoPlan;
