import { Field } from "formik";
import { useState } from "react";

const Input = ({ ...attribute }) => {
    const [isEyeClose, setIsEyeClose] = useState(true);
    return (
        <div className="password_input_container">
            <Field type={isEyeClose ? "password" : "text"} {...attribute} />
            {isEyeClose ? (
                <img
                    src="/img/eye_close.png"
                    alt="eye_open"
                    onClick={() => {
                        setIsEyeClose((v) => !v);
                    }}
                />
            ) : (
                <img
                    src="/img/eye_open.png"
                    alt="eye_close"
                    onClick={() => {
                        setIsEyeClose((v) => !v);
                    }}
                />
            )}
        </div>
    );
};

export default Input;
