import EvoPlan from "Component/evoplan";
import { axiosInstance } from "config/https";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookie from "utils/cookie";
import useDocumentTitle from "utils/hooks/useDocumentTitle";
import Button from "../../Component/Button";
import ChangePassword from "./ChangePassword";
import Evolutions from "./Evolutions";
import "./plan.css";

const SubscriptionPage = () => {
  useDocumentTitle("Votre compte", true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [meUser, setMeUser] = useState(null);
  const [portalLink, setPortalLink] = useState(null);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [showLoader, setShowLoader] = useState(true);

  let formData = {
    first_name: "",
    last_name: "",
    email: "",
  };

  const formik = useFormik({
    initialValues: formData,
    onSubmit: (values) => {
      axiosInstance
        .patch(`/api/users/${values._id}`, values)
        .then((res) => {
          toast.success("Détails du compte mis à jour avec succès");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    },
  });
  const getPromoCodeParam = searchParams.get("code")
    ? searchParams.get("code")
    : Cookie.get("code");

  const promoQueryParam = getPromoCodeParam
    ? `&prefilled_promo_code=${getPromoCodeParam}`
    : "";

  const userIdParam =
    meUser && meUser._id ? `&client_reference_id=${meUser._id}` : "";

  useEffect(() => {
    setShowLoader(true);
    axiosInstance
      .get("/api/marketing/me")
      .then((res) => {
        const response = res?.data?.data;
        setMeUser(response);
        formik.setValues({
          ...response,
        });
        if (
          response?.referrer &&
          response?.referrer === "Marketing Website" &&
          response?.subscription &&
          response?.subscription?.type !== "FREE"
        ) {
          getPortalLink();
        }
        setShowLoader(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }, []);

  const getPortalLink = () => {
    axiosInstance
      .get("/api/stripe/portal_link")
      .then((response) => {
        setPortalLink(response?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleCallback = () => {
    setShowChangePassword(false);
  };

  const signOut = () => {
    Cookie.delete("token");
    navigate("/login");
  };
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="container mt-20">
        <div className="tab-container text-14 mb-30">
          <a
            className={activeTab === "tab1" ? "active" : ""}
            onClick={() => toggleTab("tab1")}
          >
            Mon Compte
          </a>
          <a
            className={activeTab === "tab2" ? "active" : ""}
            onClick={() => toggleTab("tab2")}
          >
            évolutions
          </a>
        </div>
        {activeTab === "tab1" && (
          <>
            {showChangePassword && (
              <ChangePassword backClickHandler={handleCallback} />
            )}
            {!showChangePassword && (
              <div className="row">
                <div className="column column-6 pt-0">
                  <h3 className="m-0 mb-30 account-header">
                    Détails du compte
                  </h3>
                  <div className="basic-info-form">
                    <form
                      onSubmit={formik.handleSubmit}
                      className="from-container"
                    >
                      <div className="input-container mb-20">
                        <span className="input_label">Prénom</span>
                        <input
                          type="text"
                          placeholder="Prénom"
                          className="custom-input"
                          name="first_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.first_name}
                          max="255"
                        />
                      </div>
                      <div className="input-container mb-20">
                        <span className="input_label">Nom</span>
                        <input
                          type="text"
                          placeholder="Nom"
                          className="custom-input"
                          name="last_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.last_name}
                          max="255"
                        />
                      </div>
                      <div className="input-container mb-20">
                        <span className="input_label">Email</span>
                        <input
                          type="text"
                          placeholder="Email"
                          className="custom-input"
                          name="email"
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          max="255"
                        />
                      </div>
                      <div className="text-center mb-20">
                        <Button
                          customClass="small d-inline-block"
                          text="Enregistrer"
                        ></Button>
                      </div>
                    </form>
                  </div>
                  <div className="text-center mb-20">
                    <Button
                      onClick={() => setShowChangePassword(true)}
                      customClass="secondary-btn d-inline-block"
                      text="Changer le mot de passe"
                    />
                  </div>
                  <div className="text-center mb-20">
                    <Button
                      onClick={signOut}
                      customClass="danger-btn d-inline-block"
                      text="se deconnecter"
                    />
                  </div>
                </div>
                <div className="column column-6 pt-0">
                  <h3 className="m-0 mb-30 account-header">Mon abonnement</h3>
                  {showLoader && (
                    <div className="loader-wrapper mt-40">
                      <div className="loader"></div>
                    </div>
                  )}
                  {!showLoader && (
                    <>
                      {meUser?.subscription &&
                        meUser?.subscription?.type !== "FREE" && (
                          <>
                            <EvoPlan
                              plan="pro"
                              activePlan={
                                meUser?.subscription?.name.split(" ")[0]
                              }
                              referrer={meUser?.referrer}
                              portalLink={portalLink}
                            />
                          </>
                        )}
                      {(!meUser?.subscription ||
                        meUser?.subscription?.type === "FREE") && (
                        <div className="subscription-wrapper">
                          <div className="subscription-box mb-40">
                            <p className="title mb-10 mt-0 font-poppins clr-gry">
                              EVO BASIC
                            </p>
                            <div className="price font-weight-700">0,00€</div>
                            <p className="clr-gry mb-0 text-12">
                              Découvrez gratuitement
                            </p>
                          </div>
                          <h2 className="evo-plan-header">Passer à Evo Pro</h2>
                          <EvoPlan
                            plan="pro"
                            redirectUrl12Month={`${process.env.REACT_APP_STRIPE_URL_12_MONTHS}?locale=fr&prefilled_email=${meUser?.email}${userIdParam}${promoQueryParam}`}
                            redirectUrl1Month={`${process.env.REACT_APP_STRIPE_URL_1_MONTHS}?locale=fr&prefilled_email=${meUser?.email}${userIdParam}${promoQueryParam}`}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {activeTab === "tab2" && <Evolutions />}
      </div>
    </>
  );
};

export default SubscriptionPage;
