import "moment/locale/fr";
import moment from "moment/moment";

export function formatDate(date) {
  moment.locale("fr");
  if (date) {
    date = moment(date).format("DD MMMM YYYY");
  }
  return date;
}
