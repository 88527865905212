import { axiosInstance } from "config/https";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import "./index.css";

const Welcome = () => {
  const [searchParams] = useSearchParams();
  const getCheckoutIdParam = searchParams.get("checkoutid");
  const getPlanParam = searchParams.get("plan");
  const [dataLayer, setDataLayer] = useState({
    transactionTotal: 0,
    transactionCurrency: null,
    transactionID: null,
    transactionPromoCode: null,
    event: "awin.dl.ready",
  });

  useEffect(() => {
    if (getCheckoutIdParam) {
      getDataLayerDetails(getCheckoutIdParam);
    } else {
      callTagManager(dataLayer);
    }
  }, []);
  useEffect(() => {
    callTagManager();
  }, [dataLayer]);

  const getDataLayerDetails = (getCheckoutIdParam) => {
    axiosInstance
      .get(`/checkout/sessions/${getCheckoutIdParam}`)
      .then((res) => {
        const response = res.data.data;
        setDataLayer({
          ...dataLayer,
          transactionTotal: response.transactionTotal,
          transactionCurrency: response.transactionCurrency,
          transactionID: response.transactionID,
          transactionPromoCode: response.transactionPromoCode,
        });
        const updatedDataLayer = {
          ...dataLayer,
          transactionTotal: response.transactionTotal,
          transactionCurrency: response.transactionCurrency,
          transactionID: response.transactionID,
          transactionPromoCode: response.transactionPromoCode,
        };
        callTagManager(updatedDataLayer);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const callTagManager = (dataLayer) => {
    const tagManagerArgs = {
      dataLayer,
    };
    TagManager.dataLayer(tagManagerArgs);
  };
  return (
    <div className="welcome-box">
      <div className="container">
        <div className="welcome-row">
          {getPlanParam && (
            <span className="check-img">
              <img
                decoding="async"
                fetchpriority="high"
                src="/img/welcome-check-circle.png"
                alt="circle"
                className="wp-image-35"
              />
            </span>
          )}
          <h1>
            {getPlanParam
              ? "Confirmation de votre Abonnement"
              : "Bienvenue dans l’univers Evodream"}
          </h1>
          {getPlanParam ? (
            <div className="welcome-text-box">
              Félicitations! Votre souscription à{" "}
              <strong>Evo Pro {getPlanParam === "12mois" ? 12 : 1} mois</strong>{" "}
              est confirmée. Vous bénéficiez d'une période d'essai gratuite de 7
              jours, avec un accès complet à notre contenu premium.<br /><br />
              <strong>Téléchargez notre application pour commencer l'aventure !</strong>
            </div>
          ) : (
            <div className="welcome-text-box">
              Démarrez votre aventure vers une vie plus saine dès maintenant ! 🏋️‍♀️🥗 <br /><br />
              <strong>Téléchargez notre application de fitness et nutrition gratuitement.</strong>
            </div>
          )}
          <img className="girl-img hide-mobile" src="/img/girl_running.webp" />
          <div className="ps-buttons mt-40">
            <a
              id="apple-download-cta"
              className="mobile-download-ctas"
              href="https://apps.apple.com/us/app/evodream/id1643197949"
            >
              <span className="btn">
                <img decoding="async" src="/img/apple.png" alt="apple" />
                <span className="title">Télécharger depuis</span>
                <span className="sub-title">Apple Store</span>
              </span>
            </a>
            <a
              id="android-download-cta"
              className="mobile-download-ctas"
              href="https://play.google.com/store/apps/details?id=com.evodream.app&amp;pcampaignid=web_share"
            >
              <span className="btn">
                <img
                  decoding="async"
                  src="/img/google-play.png"
                  alt="google-play"
                />
                <span className="title">Télécharger depuis</span>
                <span className="sub-title">Google Play</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
