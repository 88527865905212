import PlanHeader from "Component/PlanHeader";
const Layout = ({ element: ReactComponent }) => {
  return (
    <>
      <PlanHeader />
      <ReactComponent />
    </>
  );
};

export default Layout;
