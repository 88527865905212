import { axiosInstance } from "config/https";
import { Form, Formik } from "formik";
import { sha512 } from "js-sha512";
import toast from "react-hot-toast";
import Button from "../../../Component/Button";
import "../plan.css";

const ChangePassword = ({ backClickHandler }) => {
  const changePassword = (values = {}) => {
    let newPass = { ...values };
    newPass.confirm_password = sha512(values.confirm_password);
    newPass.new_password = sha512(values.new_password);
    newPass.current_password = sha512(values.current_password);
    axiosInstance
      .post("/api/marketing/me/changepassword", newPass)
      .then(() => {
        toast.success("New Password set Successfully");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <div className="row">
      <div className="column column-6 change-password">
        <h3 className="mb-20 text-center mt-0 font-weight-600">
          Changer mon mot de passe
        </h3>
        <div className="basic-info-form">
          <Formik
            initialValues={{
              current_password: "",
              new_password: "",
              confirm_password: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.current_password) {
                errors.current_password = "Current Password is required";
              }

              if (!values.new_password) {
                errors.new_password = "New Password is required";
              } else if (values.new_password.length < 8) {
                errors.new_password = "New Password must have 8 characters.";
              } else if (
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{7,}$/.test(
                  values.new_password
                )
              ) {
                errors.new_password =
                  "New Password must contain 1 uppercase, \n 1 lowercase 1 number and 1 special character.";
              }

              if (!values.confirm_password) {
                errors.confirm_password = "Confirm Password is required";
              } else if (values.confirm_password !== values.new_password) {
                errors.confirm_password =
                  "Confirm Password Should be same as New Password";
              } else if (values.confirm_password.length < 8) {
                errors.confirm_password =
                  "Confirm Password must have 8 characters.";
              } else if (
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{7,}$/.test(
                  values.confirm_password
                )
              ) {
                errors.confirm_password =
                  "Confirm Password must contain 1 uppercase, \n 1 lowercase 1 number and 1 special character.";
              }
              return errors;
            }}
            onSubmit={changePassword}
          >
            {({ errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <Form>
                <div className="input-container mb-20">
                  <span className="input_label">Mot de passe actuel</span>
                  <div className="password_field">
                    <input
                      type="password"
                      name="current_password"
                      placeholder="***************"
                      className="custom-input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        border:
                          errors.current_password &&
                          touched.current_password &&
                          "2px solid red",
                      }}
                    />
                  </div>
                  {errors.current_password && touched.current_password && (
                    <span className="error">{errors.current_password}</span>
                  )}
                </div>
                <div className="input-container mb-20">
                  <span className="input_label">Nouveau mot de passe</span>
                  <div className="password_field">
                    <input
                      type="password"
                      name="new_password"
                      placeholder="***************"
                      className="custom-input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        border:
                          errors.new_password &&
                          touched.new_password &&
                          "2px solid red",
                      }}
                    />
                  </div>
                  {errors.new_password && touched.new_password && (
                    <span className="error">{errors.new_password}</span>
                  )}
                </div>
                <div className="input-container mb-20">
                  <span className="input_label">
                    Confirmer nouveau mot de passe
                  </span>
                  <div className="password_field">
                    <input
                      type="password"
                      name="confirm_password"
                      placeholder="***************"
                      className="custom-input"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        border:
                          errors.confirm_password &&
                          touched.confirm_password &&
                          "2px solid red",
                      }}
                    />
                  </div>
                  {errors.confirm_password && touched.confirm_password && (
                    <span className="error">{errors.confirm_password}</span>
                  )}
                </div>
                <div className="text-center mb-20">
                  <Button
                    onClick={handleSubmit}
                    customClass="secondary-btn d-inline-block"
                    text="Changer le mot de passe"
                  />
                </div>
                <div className="text-center ">
                  <a
                    className="text-14 no-text-decoration back-link"
                    href="Javascript:;"
                    onClick={backClickHandler}
                  >
                    Annuler
                  </a>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
