import Accordion from "Component/Accordian";
import EvoPlan from "Component/evoplan";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookie from "utils/cookie";
import useDocumentTitle from "utils/hooks/useDocumentTitle";
import "./index.css";
const Pricing = () => {
  useDocumentTitle("Démarrons votre évolution - Abonnements", true);
  const [searchParams] = useSearchParams();
  const getPromoParam = searchParams.get("code");
  const [showTab, setShowTab] = useState(2);
  const navigate = useNavigate();

  useEffect(() => {
    if (getPromoParam) {
      Cookie.save("code", getPromoParam);
    }
  }, []);
  const goToTop = (to = 0) => {
    window.scrollTo({
      top: to,
      behavior: "smooth",
    });
  };
  return (
    <div className="pricing-page">
      <div className="pricing-banner">
        <div className="container">
          <div className="row">
            <div className="column column-4 pricing-mbl-banner">
              <h1 className="header">
                Démarrons <br /> votre évolution !
              </h1>
              <span
                onClick={() => goToTop(1450)}
                className="discover-link hide-mobile no-text-decoration font-weight-600"
                title="DÉcouvrez evodream"
              >
                DÉcouvrez evodream{" "}
                <img
                  src="/svg/arrow_down.svg"
                  className="v-align-middle"
                  alt="arrow"
                />
              </span>
              <img
                src="/img/marketing/pricing_banner.webp"
                className="w-100 banner-img hide-mobile"
                alt="banner"
              />
            </div>
            <div className="column column-8">
              <div className="d-flex price-box-wrapper">
                <EvoPlan plan="basic" />
                <EvoPlan plan="pro" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="free-trial-box text-center">
          <h2 className="header">Profitez de 7 jours d’essai gratuit !</h2>
          <p className="clr-gry text-16">
            Offre valable sur l’abonnement EVO PRO
          </p>
          <div className="free-trial-steps">
            <div className="step-box">
              <span className="icon">
                <img src="/img/free_trial_step_1.png" alt="free trial step 1" />
              </span>
              <p className="title font-weight-700">Aujourd’hui</p>
              <p className="description clr-gry text-16">
                Profitez de l’ensemble de l’application. L’essai gratuit est
                résiliable à tout moment, sans aucun frais, ni prélèvement.
              </p>
            </div>
            <div className="step-box">
              <span className="icon">
                <img src="/img/free_trial_step_2.png" alt="free trial step 2" />
              </span>
              <p className="title font-weight-700">Dans 6 jours</p>
              <p className="description clr-gry text-16">
                Recevez un rappel par email de la fin de votre essai gratuit.
              </p>
            </div>
            <div className="step-box">
              <span className="icon">
                <img src="/img/free_trial_step_3.png" alt="free trial step 3" />
              </span>
              <p className="title font-weight-700">Dans 7 jours</p>
              <p className="description clr-gry text-16">
                Votre abonnement commence.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center pricing-section">
          <h2 className="header">Restez motivé.e</h2>
          <p className="clr-gry text-16 mt-0">
            Votre E-coach vous accompagne et vous aide à rester focus sur vos
            objectifs
          </p>
          <img
            className="w-100"
            src="/img/marketing/pricing_img_1.webp"
            alt="Restez motivé.e"
          />
        </div>
        <div className="text-center pricing-section">
          <h2 className="header">Atteignez vos objectifs</h2>
          <p className="clr-gry text-16 mt-0">
            Votre E-Coach vous élabore un programme d’entrainement personnalisé,
            modulable à tout moment.
          </p>
          <img
            className="w-100"
            src="/img/marketing/pricing_img_3.webp"
            alt="Atteignez vos objectifs"
          />
          <button
            id="trial-secondary-btn-pricing"
            data-id="trial-secondary-btn-pricing"
            onClick={() => navigate("/register")}
            className="customButton ctm-btn small mt-20"
          >
            essayer gratuitement
          </button>
        </div>

        <div className="row pricing-section-side-by-side">
          <div className="column column-6">
            <div className="text-center pricing-section">
              <h2 className="header">Mangez mieux</h2>
              <p className="clr-gry text-16 mt-0">
                Un plan de nutrition personnalisé conçu par votre E-Coach, avec
                des recettes simples, efficaces et rapide à réaliser.
              </p>
              <img
                className="w-100"
                src="/img/marketing/pricing_img_4.webp"
                alt="Manger mieux"
              />
            </div>
          </div>
          <div className="column column-6">
            <div className="text-center pricing-section right">
              <h2 className="header">Faites-en plus !</h2>
              <p className="clr-gry text-16 mt-0">
                En plus du contenu personnalisé créé par votre E-Coach, profitez
                d’une bibliothèque de plus de 50 programmes et plus de 300
                recettes !
              </p>
              <img
                className="w-100"
                src="/img/marketing/pricing_img_2.webp"
                alt="Faites-en plus !"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grey-bg-box">
        <div className="container">
          <div className="pricing-section faq">
            <h2 className="header mt-0 text-center pb-20 mb-30">
              Foire aux questions
            </h2>
            <Accordion
              title="Qu'est-ce que le E-COACH ?"
              content={[
                <p>
                  Le E-coach est votre coach virtuel, guidé par une intelligence
                  artificielle. Il s’agit d’un algorithme complexe conçu par
                  notre équipe de développeurs et notre équipe de professionnels
                  du sport. Votre E-coach interagit avec vous tout au long de
                  votre évolution sur les points suivants :
                </p>,
                <p>
                  – <strong>Création de vos plans d’entraînement</strong> et de
                  nutrition personnalisés selon les réponses que vous aurez
                  fournies au questionnaire. Vos réponses peuvent être
                  modifiables à tout moment dans l’onglet “e-coach”. Cela permet
                  à votre E-coach de réajuster votre programme.
                </p>,
                <p>
                  – <strong>Mise en place d’un cadre précis,</strong> avec des
                  objectifs par semaine à réaliser. Votre E-coach vous fait
                  aussi des rappels pour entretenir votre motivation.
                </p>,
                <p>
                  – <strong>Suivi sur la partie nutrition</strong> et
                  entraînement via un système de notation.
                </p>,
                <p>
                  – <strong>Réalisation de “bilans d’évolution” :</strong> à la
                  fin d’un programme, votre E-coach vous demande de faire un
                  point sur les objectifs que vous vous êtes fixés. Vous pourrez
                  ajuster votre poids, vos mensurations et intégrer des photos
                  afin que vous puissiez observer votre évolution.
                </p>,
              ]}
            />
            <Accordion
              title="Si mes objectifs ou besoin changent, que se passe t-il ?"
              content={[
                <p>
                  Le E-COACH a été conçu pour tenir compte de toute modification
                  apportée à vos objectifs, vos habitudes. Dans la section
                  “E-coach” vous pouvez revenir sur votre questionnaire et
                  apporter des modifications. Ainsi,{" "}
                  <strong>
                    Le E-coach modifiera votre programme si besoin.
                  </strong>
                </p>,
              ]}
            />
            <Accordion
              title="Les séances d'entraînement sont-elles adaptées à mon niveau ?"
              content={[
                <p>
                  Oui, le E-COACH prend en compte votre niveau et vous attribue
                  un programme en fonction de ce dernier. De plus, à la fin de
                  vos séances d’entraînement, vous pourrez évaluer votre niveau
                  de fatigue.
                </p>,
                <p>
                  Ainsi en fonction de vos ressenties,{" "}
                  <strong>
                    vous pourrez à tout moment revenir sur la partie E-coach et
                    modifier vos informations
                  </strong>{" "}
                  afin que votre E-coach vous attribue un nouveau programme.
                </p>,
              ]}
            />
            <Accordion
              title="De quoi ai-je besoin pour commencer ?"
              content={[
                <p>
                  De votre téléphone et d’une tenue de sport ! Les séances sont
                  adaptées à votre contexte de vie, elles sont réalisables{" "}
                  <strong>quand vous le voulez, où vous le souhaitez</strong> et
                  avec le matériel dont vous disposez.
                </p>,
              ]}
            />
            <div className="text-center mt-40">
              <button
                id="trial-third-btn-pricing"
                data-id="trial-third-btn-pricing"
                onClick={() => navigate("/register")}
                className="customButton ctm-btn small "
              >
                essayer gratuitement
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pricing;
