const buttonIcon = ({
  text,
  clickHandler,
  isLoading = false,
  isButtonDisable = false,
  customClass = "",
  ...rest
}) => {
  return (
    <button className={customClass + " customButton ctm-btn"} {...rest}>
      {isLoading ? "EN COURS..." : text}
    </button>
  );
};

export default buttonIcon;
