import Layout from "Layout";
import Confirmation from "pages/Confirmation";
import ChrimasMarketing from "pages/Marketing/Christmas";
import Pricing from "pages/Marketing/pricing";
import Welcome from "pages/Welcome";
import { Navigate, useRoutes } from "react-router-dom";
import Cookie from "utils/cookie";
import ForgotPassword from "../pages/ForgotPassword";
import SubscriptionPage from "../pages/SubscriptionPage";
import Login from "../pages/login";
import SignUp from "../pages/signUp";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = () => {
  const token = Cookie.get("token");
  const routes = [
    { path: "/login", element: !token ? <Login /> : <Navigate to="/" /> },
    {
      path: "/forgot-password",
      element: !token ? <ForgotPassword /> : <Navigate to="/" />,
    },
    { path: "/register", element: !token ? <SignUp /> : <Navigate to="/" /> },
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Layout element={SubscriptionPage} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bienvenue-dans-lunivers-evodream",
      element: <Layout element={Confirmation} />,
    },
    {
      path: "/welcome",
      element: <Layout element={Welcome} />,
    },
    {
      path: "/evodream-offre-noel",
      element: <Layout element={ChrimasMarketing} />,
    },
    {
      path: "/pricing",
      element: <Layout element={Pricing} />,
    },
    { path: "*", element: <Navigate to="/" /> },
  ];
  return useRoutes(routes);
};

export default AppRoutes;
