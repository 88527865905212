import { useState } from "react";
import ResponsiveMenu from "./ResponsiveMenu";

const PlanHeader = () => {
  const [isOpen, setIsopen] = useState(false);

  const toggle = () => {
    setIsopen(!isOpen);
  };
  return (
    <>
      <ResponsiveMenu isOpen={isOpen} toggle={toggle} />
      <div className="header_content">
        <div>
          <img
            src="/svg/List.svg"
            alt="logo"
            onClick={toggle}
            className="mobile_menu"
          />
        </div>
        <div className="main_logo">
          <img src="/img/logo.png" alt="logo" />
        </div>
        <div id="desktop-menu" className="header-tab">
          <a className="text-16" target="_blank" href="https://evodream.fr/">
            Accueil
          </a>
          <a
            className="text-16"
            href="/pricing"
          >
            S’abonner
          </a>
          <a
            className="text-16"
            href="https://evodream.fr/contact/"
          >
            Contact
          </a>
        </div>
      </div>
    </>
  );
};

export default PlanHeader;
