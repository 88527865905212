import axios from "axios";
import toast from "react-hot-toast";
import Cookie from "utils/cookie";

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/v2/`,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = Cookie.get("token");
    config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      toast.error("Unauthorized");
      Cookie.delete("token");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);
