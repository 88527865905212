import Button from "Component/Button";
import PasswordInput from "Component/PasswordInput";
import SocialMediaLogin from "Component/SocialMediaLogin";
import { axiosInstance } from "config/https";
import { Form, Formik } from "formik";
import { sha512 } from "js-sha512";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Cookie from "utils/cookie";
import useDocumentTitle from "utils/hooks/useDocumentTitle";

const SignUp = () => {
  useDocumentTitle("Rejoignez Evodream Aujourd’hui - Inscription", true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const param = searchParams.get("plan");
  const [showDetails, setShowDetails] = useState(false);
  const [planDetail, setPlanDetail] = useState({
    month: 1,
    pricePerMonth: "13,90",
    price: "13,90",
  });
  const getPromoCodeParam = searchParams.get("code")
    ? searchParams.get("code")
    : Cookie.get("code");
  let FormData = {
    email: "",
    password: "",
    user_type: "CLIENT",
    first_name: "",
    referrer: "Marketing Website",
    newsletter: true,
  };
  const getPlanParam = searchParams.get("plan");

  useEffect(() => {
    if (window.innerWidth > 768) {
      setShowDetails(true);
    }
    if (getPlanParam === "12mois") {
      setPlanDetail({ month: 12, pricePerMonth: "6,90", price: "82,80" });
    }
  }, []);

  const fromHandleSubmit = (data = {}) => {
    let userData = { ...data };
    userData.password = sha512(data.password);
    const promoQueryParam = getPromoCodeParam
      ? `&prefilled_promo_code=${getPromoCodeParam}`
      : "";
    const awcCookieVal = Cookie.get("awc");
    if (awcCookieVal) {
      userData.awc = awcCookieVal;
    }

    return axiosInstance
      .post("/api/marketing/auth/signup", userData)
      .then((response) => {
        const token = response.data.data["token"];
        const { user_id } = jwtDecode(token);
        const userIdParam = user_id ? `&client_reference_id=${user_id}` : "";
        if (param === "1mois" || param === null) {
          window.location.replace(
            `${process.env.REACT_APP_STRIPE_URL_1_MONTHS}?locale=fr&prefilled_email=${data.email}${promoQueryParam}${userIdParam}`
          );
        } else if (param === "6mois") {
          window.location.replace(
            `${process.env.REACT_APP_STRIPE_URL_6_MONTHS}?locale=fr&prefilled_email=${data.email}${promoQueryParam}${userIdParam}`
          );
        } else if (param === "12mois") {
          window.location.replace(
            `${process.env.REACT_APP_STRIPE_URL_12_MONTHS}?locale=fr&prefilled_email=${data.email}${promoQueryParam}${userIdParam}`
          );
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message?.message ||
            "Their is an Error While Creating an Account!"
        );
      });
  };

  return (
    <div className="row auth-box">
      <div className="column-6 column">
        <a
          href="/pricing"
          className="text-upper show-mobile mb-20 text-12 font-weight-600 clr-gry text-dec-non"
        >
          <img
            src="/img/arrow_bend_upLeft.png"
            className="mr-10 v-align-middle back-icon"
          />
          RETOUR AUX OFFRES
        </a>
        <img
          src="/img/stepper_graphic_mobile.webp"
          className="mw-100 mb-20 show-mobile"
          alt="stepper graphic"
        />
        <div className="plan-details-box">
          <img
            className="hide-mobile"
            src="/img/signup_plan_bg.webp"
            alt="Plan"
          />
          <img
            className="show-mobile"
            src="/img/signup_plan_bg_mobile.webp"
            alt="Plan"
          />
          <h1 className="auth-title mb-10 show-mobile">
            Essai gratuit de 7 jours
          </h1>
          <div className="detail-box">
            <a
              href="#"
              className="more-detail show-mobile text-dec-non clr-gry"
              onClick={() => setShowDetails(!showDetails)}
            >
              {!showDetails ? "Afficher les détails" : "Cacher les détails"}
              {!showDetails ? (
                <img
                  src="/img/caret_down.png"
                  className="v-align-middle ml-10"
                  alt="Show more"
                />
              ) : (
                <img
                  src="/img/caret_up.png"
                  className="v-align-middle ml-10"
                  alt="Show less"
                />
              )}
            </a>
            <div className="plan">
              <h2 className="name mt-0 mb-10 font-poppins">
                EVO PRO {planDetail.month} MOIS
              </h2>
              <div className="price">{planDetail.pricePerMonth}€/mois</div>
              <p className="text-18 mt-10 description">
                {planDetail.price}€ prélevés tous les mois, sans engagement et
                résiliable à tout moment.
              </p>
            </div>
            {showDetails && (
              <div className="details">
                <p className="font-weight-600">
                  Inclus dans votre abonnement :
                </p>
                <ul className="pl-20 pr-20">
                  <li>Programme “découverte”</li>
                  <li>Kit recettes démarrage</li>
                  <li>Suivi d’évolution</li>
                  <li>Accompagnement E-Coach</li>
                  <li>
                    Programmes d’entrainements personnalisés par le E-coach
                  </li>
                  <li>Programmes de nutrition personnalisés par le E-coach</li>
                  <li>+ de 50 programmes </li>
                  <li>+ de 300 recettes </li>
                </ul>
                <p className="font-weight-600">
                  Puis-je annuler à tout moment sans prélèvement immédiat sur ma
                  carte ?
                </p>
                <p>
                  Oui, vous avez la liberté d'annuler avant la fin de la période
                  d'essai de 7 jours, et rien ne sera débité sur votre carte
                  aujourd'hui. Le prélèvement se fera après la période d'essai
                  de 7 jours si vous ne résiliez pas avant.
                </p>
                <hr className="mt-20 mb-20 hide-mobile"></hr>
                <a
                  href="/pricing"
                  className="text-upper hide-mobile font-weight-700 clr-gry text-dec-non"
                >
                  <img
                    src="/img/arrow_bend_upLeft.png"
                    className="mr-10 v-align-middle"
                  />
                  RETOUR AUX OFFRES
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="column-6 column">
        <div className="auth-form-box">
          <div className="text-center">
            <img
              src="/svg/evo_logo.svg"
              className="hide-mobile"
              alt="Evo Logo"
            />
            <h1 className="auth-title mt-20 mb-20 hide-mobile">
              Essai gratuit de 7 jours
            </h1>
            <img
              src="/img/stepper_graphic.webp"
              className="mw-100 hide-mobile mb-20 mt-20"
              alt="stepper graphic"
            />
          </div>
          <SocialMediaLogin />
          <Formik
            initialValues={FormData}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Ajouter une adresse e-mail";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password) {
                errors.password = "Ajouter un mot de passe";
              } else if (values.password.length < 8) {
                errors.password = "Password must have 8 characters.";
              }
              return errors;
            }}
            onSubmit={fromHandleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="from-container">
                <div className="or-text text-14">ou</div>
                <div className="input-container">
                  <span className="input-label">Email </span>
                  <input
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    id="email"
                    name="email"
                    placeholder="e.g. johndoe@gmail.com"
                    style={{
                      border: errors.email && touched.email && "2px solid red",
                    }}
                    className="custom-input"
                  />
                  {errors.email && touched.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="input-container mt-20 mb-20">
                  <span className="input-label">Création mot de passe </span>
                  <PasswordInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    id="password"
                    name="password"
                    placeholder="Entrez votre mot de passe"
                    style={{
                      border:
                        errors.password && touched.password && "2px solid red",
                    }}
                    className="custom-input"
                  />
                  {errors.password && touched.password && (
                    <span className="error">{errors.password}</span>
                  )}
                </div>
                <div className="from-text mb-20 text-left">
                  En vous inscrivant, vous acceptez nos{" "}
                  <a
                    target="_blank"
                    className="text-black"
                    title="conditions d'utilisation"
                    href="https://evodream.fr/cguv/"
                  >
                    conditions d'utilisation
                  </a>{" "}
                  et notre{" "}
                  <a
                    target="_blank"
                    className="text-black"
                    title="politique de confidentialité"
                    href="https://evodream.fr/politique-de-confidentialite/"
                  >
                    politique de confidentialité
                  </a>
                </div>
                <div className="mb-20">
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newsletter}
                      id="newsletter"
                      name="newsletter"
                      checked={values.newsletter}
                    />{" "}
                    J'accepte de recevoir la newsletter d'Evodream
                  </label>
                </div>
                <Button
                  text="continuer"
                  type="submit"
                  isButtonDisable={isSubmitting}
                  isLoading={isSubmitting}
                />
                <div className="text-black mt-20 mb-10 text-center">
                  Déjà un compte ?{" "}
                  <Link
                    className="text-upper font-weight-700 clr-gry text-dec-non"
                    to="/login"
                    relative="path"
                  >
                    Connectez-vous
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
