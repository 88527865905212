import Accordion from "Component/Accordian";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Cookie from "utils/cookie";
import "./index.css";
const ChrimasMarketing = () => {
  const [searchParams] = useSearchParams();
  const getAwcParam = searchParams.get("awc");
  const getPromoParam = searchParams.get("code");

  useEffect(() => {
    if (getAwcParam) {
      Cookie.save("awc", getAwcParam);
    }
    if (getPromoParam) {
      Cookie.save("code", getPromoParam);
    }
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const testimonials = [
    {
      name: "Paul",
      comment:
        "Je suis restez dubitatif au début en installant cette application pensant qu’elle est comme les autres… Au final ca fait 3 mois que je l’utilise et j’ai perdu 5 kg !",
    },
    {
      name: "Jean",
      comment:
        "C’est un ami qui m’a parlé de Evodream. Après un mois d’utilisation je suis conquis, la promesse d’un coach virtuel est respecté, je m’entraine deux fois par semaine et j’ai déjà des résultats.",
    },
    {
      name: "Klea",
      comment:
        "Je pris 13 kilo durant ma grossesse, une ami m’a parlé de cette application, je m’occupe de bébé et fais 3 séances par semaine depuis la maison. Au top -4kilos pour le premier mois !",
    },
    {
      name: "Nico",
      comment:
        "Cette appli a révolutionné ma perte de poids ! Les plans nutritionnels avec des recettes au top accompagnent les séances de sport. Résultats visibles et motivation au top !",
    },
    {
      name: "Emma",
      comment:
        "Pour moi, la meilleur appli pour perdre du poids ! Les défis sportifs et les plans alimentaires équilibrés ont fait des merveilles. 6 mois après -7 kg pour 2 entrainements par semaine",
    },
    {
      name: "Youss",
      comment:
        "Impressionné par cette appli. Chaque jour, un défi différent. C’est un cadeau sportif quotidien, j’arrive à rester motivé pourvu que sa dure",
    },
    {
      name: "Nina",
      comment:
        "Transformation réelle avec des plans variés et un suivi génial. J’ai perdu du poids et gagné en tonus. Séances ludiques, résultats concrets. Vous en avez pour votre argent !",
    },
  ];
  const goToTop = () => {
    window.scrollTo({
      top: 55,
      behavior: "smooth",
    });
  };
  return (
    <div className="marketing-site">
      <div className="marketing-offers text-center">
        <h1 className="header-h1 m-0">Evodream célèbre Noël</h1>
        <p className="header-h2 pr-20 pl-20">
          Offrez 1 an d’abonnement à l’application Evodream
        </p>
        <div className="offer-box mt-40">
          <div className="row">
            <div className="column column-4 mb-40">
              <div className="d-flex offer-title">
                <div className="header-h2 font-weight-600">1 carte cadeau</div>
              </div>
              <div className="mt-20">
                <a
                  id="1-gift-card"
                  target="_blank"
                  href={`${process.env.REACT_APP_STRIPE_URL_CHRISTMAS_GIFT_CARD_1}?locale=fr`}
                >
                  <img src="/img/marketing/offer_1.webp" />
                </a>
              </div>
            </div>
            <div className="column column-4 mb-40">
              <div className="d-flex offer-title">
                <div className="header-h2 font-weight-600">
                  2 cartes cadeaux
                </div>
                <div className="price font-weight-600 text-right">
                  <span>164€</span> -10%
                </div>
              </div>
              <div className="mt-20">
                <a
                  id="2-gift-card"
                  target="_blank"
                  href={`${process.env.REACT_APP_STRIPE_URL_CHRISTMAS_GIFT_CARD_2}?locale=fr`}
                >
                  <img src="/img/marketing/offer_2.webp" />
                </a>
              </div>
            </div>
            <div className="column column-4 mb-40">
              <div className="d-flex offer-title">
                <div className="header-h2 font-weight-600">
                  3 cartes cadeaux
                </div>
                <div className="price font-weight-600 text-right">
                  <span>246€</span> -20%
                </div>
              </div>
              <div className="mt-20">
                <a
                  id="3-gift-card"
                  target="_blank"
                  href={`${process.env.REACT_APP_STRIPE_URL_CHRISTMAS_GIFT_CARD_3}?locale=fr`}
                >
                  <img src="/img/marketing/offer_3.webp" />
                </a>
              </div>
            </div>
          </div>
          <div className="header-h3 font-weight-600">
            Invitez vos proches à prendre de nouvelles résolutions pour 2024 🎁
          </div>
        </div>
      </div>
      <div className="app-marketing-box text-center">
        <h2 className="header-h4">Forme et nutrition réinventées</h2>
        <p>
          Découvrez la toute nouvelle application qui ne cesse de faire parler
          d’elle. Plongez dans un univers motivant approuvé par des milliers
          d'utilisateurs 🤩
        </p>
        <img src="/img/marketing/mobile_app_img_1.webp" alt="Rester motiver" />
      </div>
      <div className="app-marketing-box text-center">
        <h2 className="header-h4">Atteignez vos objectifs</h2>
        <p className="mb-30">
          Votre programme d’entrainement personnalisé adapté à votre niveau et
          votre contexte de vie
        </p>
        <img src="/img/marketing/mobile_app_img_2.webp" alt="Rester motiver" />
        <div className="mt-40 text-center">
          <button className="mk-button" id="faites-rever" onClick={goToTop}>
            <span>FAITES RÊVER VOS PROCHES</span>{" "}
            <img alt="icon" src="/img/marketing/icon.png" />
          </button>
        </div>
      </div>
      <div className="app-marketing-box text-center">
        <h2 className="header-h4">Mangez mieux</h2>
        <p className="mb-30">
          Votre plan de nutrition personnalisé avec des recettes simples,
          efficaces et rapides à réaliser
        </p>
        <img src="/img/marketing/mobile_app_img_3.webp" alt="Manger mieux" />
      </div>
      <div className="app-marketing-box text-center">
        <h2 className="header-h4">Explorer</h2>
        <p className="mb-30">
          Un accès illimité à plus de 50 programmes d'entraînements et plus de
          300 recettes pour ne jamais vous ennuyer
        </p>
        <img src="/img/marketing/mobile_app_img_4.webp" alt="Explorer" />
        <div className="mt-40 mb-40 text-center">
          <button className="mk-button" id="offrir" onClick={goToTop}>
            Offrir ⭐️
          </button>
        </div>
      </div>
      <div className="testimonial-box grey-bg-box text-center">
        <h2 className="header-h4">Transformez-vous comme ils l’ont fait</h2>
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.name} className="testimonial-box-slider">
              <p className="title font-weight-600">{testimonial.name}</p>
              <div className="rattings">
                <img src="/img/marketing/rattings.png" alt="Rattings" />
              </div>
              <p>{testimonial.comment}</p>
            </div>
          ))}
        </Slider>
      </div>
      <div className="grey-bg-box">
        <h2 className="header-h4 mt-0 text-center mb-30">
          Foire Aux Questions
        </h2>
        <Accordion
          title="Qu'est-ce que le E-COACH ?"
          content={[
            <p>
              Le E-coach est votre coach virtuel, guidé par une intelligence
              artificielle. Il s’agit d’un algorithme complexe conçu par notre
              équipe de développeurs et notre équipe de professionnels du sport.
              Votre E-coach interagit avec vous tout au long de votre évolution
              sur les points suivants :
            </p>,
            <p>
              – <strong>Création de vos plans d’entraînement</strong> et de
              nutrition personnalisés selon les réponses que vous aurez fournies
              au questionnaire. Vos réponses peuvent être modifiables à tout
              moment dans l’onglet “e-coach”. Cela permet à votre E-coach de
              réajuster votre programme.
            </p>,
            <p>
              – <strong>Mise en place d’un cadre précis,</strong> avec des
              objectifs par semaine à réaliser. Votre E-coach vous fait aussi
              des rappels pour entretenir votre motivation.
            </p>,
            <p>
              – <strong>Suivi sur la partie nutrition</strong> et entraînement
              via un système de notation.
            </p>,
            <p>
              – <strong>Réalisation de “bilans d’évolution” :</strong> à la fin
              d’un programme, votre E-coach vous demande de faire un point sur
              les objectifs que vous vous êtes fixés. Vous pourrez ajuster votre
              poids, vos mensurations et intégrer des photos afin que vous
              puissiez observer votre évolution.
            </p>,
          ]}
        />
        <Accordion
          title="Si mes objectifs ou besoin changent, que se passe t-il ?"
          content={[
            <p>
              Le E-COACH a été conçu pour tenir compte de toute modification
              apportée à vos objectifs, vos habitudes. Dans la section “E-coach”
              vous pouvez revenir sur votre questionnaire et apporter des
              modifications. Ainsi,{" "}
              <strong>Le E-coach modifiera votre programme si besoin.</strong>
            </p>,
          ]}
        />
        <Accordion
          title="Les séances d'entraînement sont-elles adaptées à mon niveau ?"
          content={[
            <p>
              Oui, le E-COACH prend en compte votre niveau et vous attribue un
              programme en fonction de ce dernier. De plus, à la fin de vos
              séances d’entraînement, vous pourrez évaluer votre niveau de
              fatigue.
            </p>,
            <p>
              Ainsi en fonction de vos ressenties,{" "}
              <strong>
                vous pourrez à tout moment revenir sur la partie E-coach et
                modifier vos informations
              </strong>{" "}
              afin que votre E-coach vous attribue un nouveau programme.
            </p>,
          ]}
        />
        <Accordion
          title="De quoi ai-je besoin pour commencer ?"
          content={[
            <p>
              De votre téléphone et d’une tenue de sport ! Les séances sont
              adaptées à votre contexte de vie, elles sont réalisables{" "}
              <strong>quand vous le voulez, où vous le souhaitez</strong> et
              avec le matériel dont vous disposez.
            </p>,
          ]}
        />
      </div>
    </div>
  );
};
export default ChrimasMarketing;
