import Button from "Component/Button";
import { axiosInstance } from "config/https";
import { Formik } from "formik";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  let FormData = {
    email: "",
  };

  const fromHandleSubmit = (data = {}) => {
    return axiosInstance
      .post("/api/marketing/auth/forgotpassword", data)
      .then((response) => {
        toast.success(response.data.data.message, { icon: "" });
        navigate("/login");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message, { icon: "" });
      });
  };
  return (
    <div className="auth_background">
      <div className="auth_container">
        <img src="/svg/logo.svg" alt="next" />
        <h1 className="auth_title mt-30 mb-10">Mot de passe oublié ?</h1>
        <div className="from-text mb-40 clr-gry">
          Pas de problème, indiquez-nous votre email et nous vous renverrons un
          mot de passe temporaire.
        </div>
        <Formik
          initialValues={FormData}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Ajouter une adresse e-mail";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              fromHandleSubmit(values);
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="from-container" onSubmit={handleSubmit}>
              <div className="input-container mb-30">
                <span className="input_label text-upper">Email </span>
                <input
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  id="email"
                  name="email"
                  placeholder="e.g. johndoe@gmail.com"
                  style={{
                    border: errors.email && touched.email && "2px solid red",
                  }}
                  className="custom-input clr-white"
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <Button
                text="Réinitialiser mot de passe"
                isButtonDisable={isSubmitting}
                isLoading={isSubmitting}
              />
              <div className="text-16 text-center clr-white mt-30">
                Déjà un compte ?{" "}
                <Link to="/login" className="clr-white">
                  {" "}
                  Connectez vous
                </Link>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
